// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJd9oVlvLMlmmcI8by__iw0Pv0z0ErAiU",
  authDomain: "ngocbaocac.firebaseapp.com",
  databaseURL: "https://ngocbaocac-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ngocbaocac",
  storageBucket: "ngocbaocac.appspot.com",
  messagingSenderId: "984361599340",
  appId: "1:984361599340:web:a9e6599fee0a16e602f349",
  measurementId: "G-2F6TG1CTSH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firestore
const firestore = getFirestore(app);

// Initialize Database
const database = getDatabase(app) ?? getDatabase();

export { firestore, database };
import React, { PropsWithChildren, useState } from "react";
import { initialPageState } from "./MenuPageInitialState";
import { PageNewState } from "./MenuPageState";

export const MenuPageContext = React.createContext({
  pageState: initialPageState,
  setPageState: (pageState: PageNewState) => { }
});

export const MenuPageStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [pageState, setPageState]= useState(initialPageState);

  const context = {
    pageState,
    setPageState,
  };

  return (
    <MenuPageContext.Provider value={context}>
      {children}
    </MenuPageContext.Provider>
  )
};
import { PageState } from "./AppState";

export const initPageState: PageState = {
    categories: {},
    products: {},
    selectedCategoryId: null,
};

export enum ACTION {
    SAVE_CATEGORY = "Save Category",
    SAVE_PRODUCTS = "Save Products",
};
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ApplicationPage, Pages } from "../../app/application-page";
import "./Menu.css";
import { usePageState } from "./usePageState";
import { useAppState } from "../../app/usePageState";

export default function MenuPageContent() {
  const [pageState, setPageState] = usePageState();
  const [appState] = useAppState();

  const onClickedToggleMenu = (): void => {
    setPageState((prevState) => ({
      ...prevState,
      isShowToggleMenu: !prevState.isShowToggleMenu,
    }));
  };

  const onClickedToggleCategory = (): void => {
    setPageState((prevState) => ({
      ...prevState,
      isShowToggleCategory: !prevState.isShowToggleCategory,
    }));
  }

  useEffect(() => {
    if (!appState.categories) return;

    const categories = Object.values(appState.categories);
    setPageState((prevState) => ({
      ...prevState,
      categories,
    }));
  }, [appState.categories]);

  return (
    <>
      <aside className="vertical_menu_area with_scroll" tabIndex={1}>
        <div className="vertical_menu_area_inner menu">
          <div className="vertical_area_background"></div>
          <div className="vertical_logo_wrapper">
            <div className="q_logo_vertical">
              <NavLink to={Pages[ApplicationPage.TRANG_CHU].path}>
                <img itemProp="image" className="normal" src="/img/logo_name.jpg" alt="Logo" />
              </NavLink>
            </div>
          </div>
          <nav className="vertical_menu dropdown_animation vertical_menu_toggle">
            <ul>
              <li className="menu-item narrow">
                <NavLink to={Pages[ApplicationPage.TRANG_CHU].path}
                  className={({ isActive }) => isActive ? "current" : ""}>
                  <span>Trang chủ</span>
                </NavLink>
              </li>
              <li className="menu-item narrow">
                <a onClick={onClickedToggleCategory}>
                  <span>Sản phẩm</span>
                  <span className="plus">
                    {
                      !pageState.isShowToggleCategory && (
                        <i className="fa fa-plus"></i>
                      )
                    }
                    {
                      pageState.isShowToggleCategory && (
                        <i className="fa fa-minus"></i>
                      )
                    }
                  </span>
                </a>
                <nav className="vertical_menu dropdown_animation vertical_menu_toggle menu_category">
                  <ul className={pageState.isShowToggleCategory ? "show" : ""}>
                    {
                      pageState.categories.map(item => (
                        <li className="menu-item" key={item.categoryId}>
                          <NavLink to={Pages[ApplicationPage.SAN_PHAM].path}
                            state={{ categoryId: item.categoryId }}
                            className={({ isActive }) => isActive ? "current" : ""}>
                            <span>{item.categoryName}</span>
                          </NavLink>
                        </li>
                      ))
                    }
                  </ul>
                </nav>
              </li>
              <li className="menu-item narrow">
                <NavLink to={Pages[ApplicationPage.THONG_TIN_CUA_HANG].path}
                  className={({ isActive }) => isActive ? "current" : ""}>
                  <span>Thông tin cửa hàng</span>
                </NavLink>
              </li>
              <li className="menu-item narrow">
                <NavLink to={Pages[ApplicationPage.LIEN_HE].path}
                  className={({ isActive }) => isActive ? "current" : ""}>
                  <span>Liên hệ</span>
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="vertical_menu_area_widget_holder">
            <span className="q_social_icon_holder normal_social">
              <a href="https://www.instagram.com/ngocbaocac/" target="_blank" rel="noreferrer">
                <span className="qode_icon_font_elegant social_instagram simple_social"></span>
              </a>
            </span>
            <span className="q_social_icon_holder normal_social">
              <a href="https://www.facebook.com/ngocbaocac/" target="_blank" rel="noreferrer">
                <span className="qode_icon_font_elegant social_facebook simple_social"></span>
              </a>
            </span>
          </div>
        </div>
      </aside>
      <header className="page_header regular centered_logo">
        <div className="header_inner">
          <div className="header_bottom">
            <div className="container">
              <div className="container_inner">
                <div className="header_inner_left">
                  <div className="mobile_menu_button" onClick={onClickedToggleMenu}>
                    <span>
                      <i className="qode_icon_font_awesome fa fa-bars "></i>
                    </span>
                  </div>
                  <div className="logo_wrapper">
                    <div className="q_logo">
                      <a itemProp="url" href="/">
                        <img itemProp="image" className="normal" src="/img/logo_name.jpg" alt="Logo" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nav className="mobile_menu">
              <ul className={pageState.isShowToggleMenu ? "show" : ""}>
                <li className="menu-item">
                  <NavLink to={Pages[ApplicationPage.TRANG_CHU].path}
                    className={({ isActive }) => isActive ? "current" : ""}
                    onClick={onClickedToggleMenu}>
                    <span>Trang chủ</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <a onClick={onClickedToggleCategory}>
                    <span>Sản phẩm</span>
                    <span className="plus">
                    {
                      !pageState.isShowToggleCategory && (
                        <i className="fa fa-plus"></i>
                      )
                    }
                    {
                      pageState.isShowToggleCategory && (
                        <i className="fa fa-minus"></i>
                      )
                    }
                  </span>
                  </a>
                  <nav className="mobile_menu menu_category">
                    <ul className={pageState.isShowToggleCategory ? "show" : ""}>
                      {
                        pageState.categories.map(item => (
                          <li className="menu-item" key={item.categoryId}>
                            <NavLink to={Pages[ApplicationPage.SAN_PHAM].path}
                              state={{ categoryId: item.categoryId }}
                              onClick={onClickedToggleMenu}
                              className={({ isActive }) => isActive && appState.selectedCategoryId === item.categoryId ? "current" : ""}>
                              <span>{item.categoryName}</span>
                            </NavLink>
                          </li>
                        ))
                      }
                    </ul>
                  </nav>
                </li>
                <li className="menu-item">
                  <NavLink to={Pages[ApplicationPage.THONG_TIN_CUA_HANG].path}
                    className={({ isActive }) => isActive ? "current" : ""}
                    onClick={onClickedToggleMenu}>
                    <span>Thông tin cửa hàng</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to={Pages[ApplicationPage.LIEN_HE].path}
                    className={({ isActive }) => isActive ? "current" : ""}
                    onClick={onClickedToggleMenu}>
                    <span>Liên hệ</span>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

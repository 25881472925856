import React, { useEffect } from "react";
import { ItemCard } from "../item-card/ItemCard";
import "./ItemList.css";
import { useAppState } from '../../app/usePageState';
import { useGetProductByCriteriaHook } from "../../services";
import { useLocation } from "react-router-dom";
import { usePageState } from "./usePageState";

export function ItemListPageContent() {
  const [pageState, setPageState] = usePageState();
  const [appState, setAppState] = useAppState();
  const location = useLocation();

  const [getProductsResponse, getProductsRequest] = useGetProductByCriteriaHook();

  const inputChanged = (event: any) => {
    const { value } = event.target;
    getProductsRequest({ search: value, categoryId: pageState.categoryId });
  };

  const onGetProductsResponse = () => {
    if (!getProductsResponse) return;

    setPageState((prevState) => ({
      ...prevState,
      products: getProductsResponse,
    }));
  };

  useEffect(() => {
    if (!appState.products) return;

    const products = Object.values(appState.products)
      .sort((a, b) => new Date(a.updateDateTime).getTime() - new Date(b.updateDateTime).getTime()).reverse();
    setPageState((prevState) => ({
      ...prevState,
      products,
    }));
  }, [appState.products])

  useEffect(() => {
    if (!location.state) return;

    const state = location.state;
    setPageState((prevState) => ({
      ...prevState,
      categoryId: state.categoryId,
    }));
    setAppState({
      ...appState,
      selectedCategoryId: state.categoryId,
    })
    getProductsRequest({ categoryId: state.categoryId });
  }, [location]);

  useEffect(onGetProductsResponse, [getProductsResponse]);

  return (
    <>
      <div className="widget">
        <div className="search-form">
          <form>
            <label className="screen-reader-text" htmlFor="s">Tìm kiếm:</label>
            <input type="text" name="s" onChange={inputChanged} placeholder="Tìm kiếm sản phẩm" />
            <input type="submit" disabled className="search-icon" value="" />
          </form>
        </div>
      </div>
      <div className="product-list qode-pl-holder qode-standard-layout qode-normal-space qode-three-columns qode-info-below-image">
        <div className="qode-pl-outer">
          {pageState.products.map(item =>
            <ItemCard key={item.itemId} item={item}></ItemCard>
          )}
        </div>
      </div>
    </>
  );
}
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ApplicationPage, Pages } from '../../app/application-page';
import { Product } from '../../services';
import { useAppState } from '../../app/usePageState';

export function ItemCard({ item }: { item: Product }) {
  const [appState] = useAppState();
  return (
    <>
      <div className="qode-pli qode-woo-image-normal-width product type-product status-publish first instock product_cat-vases product_tag-ceramic has-post-thumbnail shipping-taxable purchasable product-type-simple">
        <div className="qode-pli-inner">
          <div className="qode-pli-image">
            {item.discount && <span className="qode-pli-onsale">{item.discount}</span>}
            <img loading="lazy"
              src={item.imgSrcList ? item.imgSrcList[0] : "/img/logo_name.jpg"}
              className="attachment-shop_catalog size-shop_catalog wp-post-image"
              alt={item.title}
            />
          </div>
          <div className="qode-pli-text">
            <div className="qode-pli-text-outer">
              <div className="qode-pli-text-inner">
              </div>
            </div>
          </div>
          <NavLink className="qode-pli-link"
            itemProp="url"
            to={`${Pages[ApplicationPage.SAN_PHAM].path}/${item.itemId}`}
            relative={"route"}
            title={item.title}></NavLink>
        </div>
        <div className="qode-pli-text-wrapper text-left">
          <h6 itemProp="name"
            className="entry-title qode-pli-title">
            {/* <a itemProp="url"
              href="https://bridge267.qodeinteractive.com/product/mini-plate/">
              {item.title}
            </a> */}
            <NavLink className="qode-pli-link"
              itemProp="url"
              to={`${Pages[ApplicationPage.SAN_PHAM].path}/${item.itemId}`}
              replace={true}
              relative={"route"}
              title={item.title}>
              {item.title}
            </NavLink>
            {/* <a itemProp="url"
              href="https://bridge267.qodeinteractive.com/product/mini-plate/">
              {item.title}
            </a> */}
          </h6>
          <p className="qode-pli-category">
            {/* <a href="https://bridge267.qodeinteractive.com/product-category/vases/" rel="tag">
              {item.categoryName}
            </a> */}
            <NavLink className="qode-pli-link"
              itemProp="url"
              to={`${Pages[ApplicationPage.SAN_PHAM].path}/${item.itemId}`}
              replace={true}
              relative={"route"}
              title={item.title}>
              {appState.categories[item.categoryId]?.categoryName ?? "N/A"}
            </NavLink>
          </p>
          <div className="qode-pli-price">
            <span className="woocommerce-Price-amount amount">
              <bdi>
                {item.price}
                <span className="woocommerce-Price-currencySymbol"> VNĐ</span>
              </bdi>
            </span>
          </div>
          <div className="qode-pli-add-to-cart">
            <a rel="nofollow" className="add_to_cart_button ajax_add_to_cart qode-button">Add to cart</a>
          </div>
        </div>
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import { database } from "../../config";
import { onValue, orderByChild, query, ref } from "firebase/database";
import { Product, ProductCriteria, ProductDictionary } from "./Product";

export const useGetProductHook = () => {
    const [request, setRequest] = useState<ProductCriteria | null>(null);
    const [response, setResponse] = useState<ProductDictionary>();

    useEffect(() => {
        if (!request) return;

        const productsRef = ref(database, 'products');
        let queryData = query(productsRef, orderByChild("updateDateTime"));
        const unsubscribe = onValue(queryData, (snapshot) => {
            if (!snapshot.size) return;

            let products: ProductDictionary = snapshot.val();
            if (request.search) {
                const results = { ...products };
                products = Object.fromEntries(Object.values(results)
                    .filter(i => i.title?.includes(request.search || ""))
                    .map(item => [item.itemId, item]));
            }
            setResponse(products);
        });
        return () => unsubscribe();
    }, [request]);

    return [response, (request: ProductCriteria | null = null) => setRequest(request)] as const;
};

export const useGetProductByCriteriaHook = () => {
    const [request, setRequest] = useState<ProductCriteria | null>(null);
    const [response, setResponse] = useState<Product[]>();

    useEffect(() => {
        if (!request) return;

        const productsRef = ref(database, 'products');
        let queryData = query(productsRef, orderByChild("updateDateTime"));
        const unsubscribe = onValue(queryData, (snapshot) => {
            if (!snapshot.size) return;

            const results: ProductDictionary = snapshot.val();
            let products = Object.values(results);
            if (request.search) {
                products = products
                    .filter(i => i.title?.toLocaleLowerCase().includes(request.search?.toLocaleLowerCase() || "") ||
                        i.itemId?.includes(request.search || ""))
            }
            if (request.categoryId) {
                products = products.filter(i => i.categoryId === request.categoryId);
            }
            setResponse(products.sort((a,b) => new Date(a.updateDateTime).getTime() - new Date(b.updateDateTime).getTime()).reverse());
        });
        return () => unsubscribe();
    }, [request]);

    return [response, (request: ProductCriteria | null = null) => setRequest(request)] as const;
};

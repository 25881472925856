import { PageState } from "./ItemDetailPageState";

export const initialPageState: PageState = {
  isShowDescription: false,
  isShowDimensions: false,
  item: undefined,
  relatedItems: [],
  offset: {
    left: 0,
    top: 0,
  },
  opacity: 0,
  selectedMainImage: "/img/logo_name.jpg",
};

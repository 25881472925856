import { useContext } from "react";
import { ItemListPageContext } from "./ItemListPageProvider";
import { PageNewState } from "./ItemListPageState";

export const usePageState = () => {
  const {
    pageState,
    setPageState,
  } = useContext(ItemListPageContext);

  const updater = (pageNewState: PageNewState) => {
    const reducer =
      pageNewState instanceof Function ? pageNewState : () => pageNewState;

    setPageState((prevState) => reducer(prevState));
  };

  return [pageState, updater] as const;
};
import { useContext } from "react";
import { PageNewState } from "./AppState";
import { AppContext } from "./AppContext";

export const useAppState = () => {
  const {
    pageState,
    setPageState,
  } = useContext(AppContext);

  const updater = (pageNewState: PageNewState) => {
    const reducer =
      pageNewState instanceof Function ? pageNewState : () => pageNewState;

    setPageState((prevState) => reducer(prevState));
  };

  return [pageState, updater] as const;
};
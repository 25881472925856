import React, { useEffect, useState } from "react";
import { useAddProductHook, useGetProductIdHook } from "../../services/product-admin";
import { useAppState } from "../../app/usePageState";
import { Product } from "../../services";
import "./ItemList.css";

const getInit = () => {
  const init: Product = {
    description: "",
    isDelete: false,
    certificationNo: "",
    discount: null,
    height: "",
    imgSrcList: [],
    length: "",
    updateDateTime: new Date().toString(),
    weight: "",
    width: "",
    categoryId: "",
  };
  return init;
}

export function ItemListAdmin() {
  const [item, setItem] = useState<Product>(getInit());
  const [appState] = useAppState();

  const [addProductResponse, addProductRequest] = useAddProductHook();
  const [itemId, itemIdRequest] = useGetProductIdHook();

  const inputChanged = (event: any) => {
    const { name, value } = event.target;
    setItem((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const selectChanged = (event: any) => {
    const { value } = event.target;
    setItem((prev) => ({
      ...prev,
      categoryId: value,
    }));
  };

  const onSaveClicked = () => {
    const request: Product = {
      ...getInit(),
      ...item,
    };
    addProductRequest(request);
  };

  const onAddProductResponse = () => {
    if (!addProductResponse) return;

    setItem((prevState) => ({
      ...prevState,
      ...getInit()
    }));
    itemIdRequest();
  }

  useEffect(onAddProductResponse, [addProductResponse]);

  useEffect(() => {
    itemIdRequest();
  }, []);

  useEffect(() => {
    if (!itemId) return;

    setItem((prevState) => ({
      ...prevState,
      itemId: itemId.toString(),
    }))
  }, [itemId]);

  return (
    <div className="admin form">
      <div>
        <label htmlFor="itemId">ItemId</label>
        <input name="itemId" value={item.itemId} onChange={inputChanged} />
      </div>
      <div>
        <label htmlFor="categoryId">CategoryId</label>
        <select name="categoryId" onChange={selectChanged} value={item.categoryId}>
          {
            Object.values(appState.categories).map(i => (
              <option key={i.categoryId} value={i.categoryId}>{i.categoryName}</option>
            ))
          }
        </select>
      </div>
      <div>
        <label htmlFor="title">Title</label>
        <input name="title" value={item.title} onChange={inputChanged} />
      </div>
      <div>
        <label htmlFor="price">Price</label>
        <input name="price" value={item.price} onChange={inputChanged} />
      </div>
      <div>
        <label htmlFor="length">Length</label>
        <input name="length" value={item.length} onChange={inputChanged} />
      </div>
      <div>
        <label htmlFor="width">Width</label>
        <input name="width" value={item.width} onChange={inputChanged} />
      </div>
      <div>
        <label htmlFor="height">Height</label>
        <input name="height" value={item.height} onChange={inputChanged} />
      </div>
      <div>
        <label htmlFor="weight">Weight</label>
        <input name="weight" value={item.weight} onChange={inputChanged} />
      </div>
      <div>
        <label htmlFor="certificationNo">CertificationNo</label>
        <input name="certificationNo" value={item.certificationNo} onChange={inputChanged} />
      </div>
      <button onClick={onSaveClicked}>Submit</button>
    </div>
  );
};
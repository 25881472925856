import React, { PropsWithChildren, useState } from "react";
import { initialPageState } from "./ItemDetailPageInitialState";
import { PageNewState } from "./ItemDetailPageState";

export const ItemDetailPageContext = React.createContext({
  pageState: initialPageState,
  setPageState: (pageState: PageNewState) => { }
});

export const ItemDetailPageStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [pageState, setPageState]= useState(initialPageState);

  const context = {
    pageState,
    setPageState,
  };

  return (
    <ItemDetailPageContext.Provider value={context}>
      {children}
    </ItemDetailPageContext.Provider>
  )
};
import { useContext } from "react";
import { ItemDetailPageContext } from "./ItemDetailPageProvider";
import { PageNewState } from "./ItemDetailPageState";

export const usePageState = () => {
  const {
    pageState,
    setPageState,
  } = useContext(ItemDetailPageContext);

  const updater = (pageNewState: PageNewState) => {
    const reducer =
      pageNewState instanceof Function ? pageNewState : () => pageNewState;

    setPageState((prevState) => reducer(prevState));
  };

  return [pageState, updater] as const;
};
import React, { useEffect, useRef } from "react";
import styled from 'styled-components';
import styles from "./ItemDetail.module.css";
import { useParams } from "react-router-dom";
import { usePageState } from "./usePageState";
import { PageState } from "./ItemDetailPageState";
import { ApplicationPage, Pages } from "../../app/application-page";
import { NavLink } from "react-router-dom";
import { useAppState } from "../../app/usePageState";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  cursor: pointer;
  :hover {
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.55), 0 14px 18px rgba(0, 0, 0, 0.55);
  }
`;

const Image: any = styled.img.attrs((props: any) => ({
  src: props.source,
  loading: props.loading,
  width: props.width,
  height: props.height,
}))``;

const Target: any = styled(Image)`
  position: absolute;
  left: ${(props: any) => props.offset.left}px;
  top: ${(props: any) => props.offset.top}px;
  opacity: ${(props: any) => props.opacity};
`;

{/* <div className="qode-pli-image" ref={containerRef}
  onMouseEnter={handleMouseEnter}
  onMouseLeave={handleMouseLeave}
  onMouseMove={handleMouseMove}>
  <Image ref={sourceRef} alt="source" loading="lazy" width="800" height="1024" source="https://bridge267.qodeinteractive.com/wp-content/uploads/2019/10/h1-img-01-100x100.jpg" />
  <Target
    ref={targetRef}
    alt="target"
    opacity={opacity}
    offset={offset}
    source="https://bridge267.qodeinteractive.com/wp-content/uploads/2019/10/h1-img-01-800x1024.jpg"
  />
</div> */}

export const ItemDetailPageContent = () => {
  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  const containerRef = useRef(null);
  const [pageState, setPageState] = usePageState();
  const [appState] = useAppState();
  const params = useParams();

  const handleShowHideAdditionalInformation = (key: keyof PageState) => {
    setPageState((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const handleMouseEnter = () => {
    setPageState((prevState) => ({
      ...prevState,
      opacity: 1
    }));
  };

  const handleMouseLeave = () => {
    setPageState((prevState) => ({
      ...prevState,
      opacity: 0
    }));
  };

  const handleMouseMove = (e: any) => {
    if (!targetRef?.current || !sourceRef?.current || !containerRef?.current) return;

    const targetRect = (targetRef.current as any).getBoundingClientRect();
    const sourceRect = (sourceRef.current as any).getBoundingClientRect();
    const containerRect = (containerRef.current as any).getBoundingClientRect();

    const xRatio = (targetRect.width - containerRect.width) / sourceRect.width;
    const yRatio =
      (targetRect.height - containerRect.height) / sourceRect.height;
    const left = Math.max(
      Math.min(e.pageX - sourceRect.left, sourceRect.width),
      0
    );
    const top = Math.max(
      Math.min(e.pageY - sourceRect.top, sourceRect.height),
      0
    );

    setPageState((prevState) => ({
      ...prevState,
      offset: {
        left: left * -xRatio,
        top: top * -yRatio
      }
    }))
  };

  const initializeData = (): void => {
    if (!params?.id || !Object.keys(appState.products).length) return;

    const product = appState.products[params.id ?? ""];
    const relatedProducts = Object.values(appState.products)
      .filter(i => i.categoryId === product.categoryId && i.itemId !== product.itemId)
      .sort((a, b) => new Date(a.updateDateTime).getTime() - new Date(b.updateDateTime).getTime())
      .reverse();
    setPageState((prevState) => ({
      ...prevState,
      item: product,
      selectedMainImage: product && product.imgSrcList ? product.imgSrcList[0] : "/img/logo_name.jpg",
      relatedItems: relatedProducts.slice(0, 4),
    }))
  };

  const onImageSelected = (image: string): void => {
    setPageState((prev) => ({
      ...prev,
      selectedMainImage: image,
    }));
  };

  useEffect(() => {
    initializeData();
  }, [params, appState])

  return (
    <>
      {pageState.item && (
        <div className={styles.container}>
          <div className={`${styles.container_inner} default_template_holder clearfix`}>
            <div className="woocommerce-notices-wrapper"></div>
            <div className="product type-product status-publish first has-post-thumbnail shipping-taxable purchasable product-type-simple">
              <div className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images">
                <figure className="woocommerce-product-gallery__wrapper">
                  <div className="woocommerce-product-gallery__image">
                    <Container ref={containerRef}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onMouseMove={handleMouseMove}>
                      <Image ref={sourceRef} className="wp-post-image" source={pageState.selectedMainImage} />
                      <Target
                        ref={targetRef}
                        alt="target"
                        opacity={pageState.opacity}
                        offset={pageState.offset}
                        source={pageState.selectedMainImage}
                      />
                    </Container>
                  </div>
                  {pageState.item.imgSrcList && pageState.item.imgSrcList.map(i => (
                    <div key={i} className="woocommerce-product-gallery__image sub-image"
                      onClick={() => onImageSelected(i)}>
                      <img src={i} alt={pageState.item?.title} title={pageState.item?.title} />
                    </div>
                  ))}
                </figure>
              </div>
              <div className="qode-single-product-summary">
                <div className="summary entry-summary">
                  <div className="clearfix">
                    <h1 className="product_title entry-title">{pageState.item.title}</h1>
                    <p className="price">
                      <span className="woocommerce-Price-amount amount">
                        <bdi>
                          {pageState.item.price}
                          <span className="woocommerce-Price-currencySymbol"> VNĐ</span>
                        </bdi>
                      </span>
                    </p>
                    <div className="woocommerce-product-details__short-description">
                      <p>{pageState.item.description !== "" ? pageState.item.description : "Đang trong giai đoạn phát triển"}</p>
                    </div>
                    <div className="product_meta">
                      <span className="sku_wrapper">
                        Mã sản phẩm:
                        <span className={`sku ${styles.information}`}>{pageState.item.itemId}</span>
                      </span>
                      <span className="posted_in">
                        Danh mục:
                        <a className={`sku ${styles.information}`} rel="tag">{appState.categories ? appState.categories[pageState.item.categoryId]?.categoryName : "N/A"}</a>
                      </span>
                    </div>
                    <div className="q_accordion_holder toggle boxed woocommerce-accordion accordion ui-accordion ui-accordion-icons ui-widget ui-helper-reset">
                      <h6 className="title-holder clearfix description_tab ui-accordion-header ui-helper-reset ui-state-default ui-corner-top ui-corner-bottom"
                        onClick={() => handleShowHideAdditionalInformation("isShowDescription")}>
                        <span className="tab-title">Thông tin sản phẩm</span>
                      </h6>
                      <div className={`accordion_content ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom ${pageState.isShowDescription ? "show" : ""}`}>
                        <div className="accordion_content_inner">
                        <p>{pageState.item.description !== "" ? pageState.item.description : "Đang trong giai đoạn phát triển"}</p>
                        </div>
                      </div>
                      <h6 className="title-holder clearfix description_tab ui-accordion-header ui-helper-reset ui-state-default ui-corner-top ui-corner-bottom"
                        onClick={() => handleShowHideAdditionalInformation("isShowDimensions")}>
                        <span className="tab-title">Thông tin phụ</span>
                      </h6>
                      <div className={`accordion_content ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom ${pageState.isShowDimensions ? "show" : ""}`}>
                        <div className="accordion_content_inner">
                          <table className="woocommerce-product-attributes shop_attributes">
                            <tbody>
                              <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--dimensions">
                                <th className="woocommerce-product-attributes-item__label">Cân nặng</th>
                                <td className="woocommerce-product-attributes-item__value">{pageState.item.weight ? pageState.item.weight : "N/A"} gram</td>
                              </tr>
                              <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--dimensions">
                                <th className="woocommerce-product-attributes-item__label">Kích thước</th>
                                <td className="woocommerce-product-attributes-item__value">{pageState.item.length} × {pageState.item.width} × {pageState.item.height} mm</td>
                              </tr>
                            </tbody></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="related products">
                <h4 className="qode-related-upsells-title">Các sản phẩm liên quan</h4>
                <ul className="products column-4">
                  {
                    pageState.relatedItems.map(item => (
                      <li key={item.itemId} className="product type-product status-publish instock has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="top-product-section">
                          <NavLink className="product-category"
                            itemProp="url"
                            to={`${Pages[ApplicationPage.SAN_PHAM].path}/${item.itemId}`}
                            title={item.title}>
                            <span className="image-wrapper">
                              <img src={item.imgSrcList ? item.imgSrcList[0] : "/img/logo_name.jpg"}
                                className={`attachment-woocommerce_thumbnail size-woocommerce_thumbnail ${styles.product_image}`} />
                            </span>
                          </NavLink>
                        </div>
                        <NavLink className="product-category product-info"
                          itemProp="url"
                          to={`${Pages[ApplicationPage.SAN_PHAM].path}/${item.itemId}`}
                          replace={true}
                          relative={"route"}
                          title={item.title}>
                          <h6 itemProp="name">{item.title}</h6>
                          <span className="price">
                            <span className="woocommerce-Price-amount amount">
                              <bdi>
                                {item.price}
                                <span className="woocommerce-Price-currencySymbol"> VND</span>
                              </bdi>
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
}
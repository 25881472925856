import React from "react";
import { ItemDetailPageContent } from "./ItemDetailPageContent"
import { ItemDetailPageStateProvider } from "./ItemDetailPageProvider"

export const ItemDetailPage = () => {
  return (
    <ItemDetailPageStateProvider>
      <ItemDetailPageContent />
    </ItemDetailPageStateProvider>
  )
}
import React from "react";
import { initPageState } from "./AppStateInitial";
import { PageNewState } from "./AppState";
import { CategoryDictionary } from "../services/category";
import { ProductDictionary } from "../services";

export const AppContext = React.createContext({
    pageState: initPageState,
    setPageState: (pageState: PageNewState) => { },
    // setCategories: (categories: CategoryDictionary) => { },
    // setProducts: (products: ProductDictionary) => { },
});
import React, { PropsWithChildren, useState } from "react";
import { initialPageState } from "./ItemListPageInitialState";
import { PageNewState } from "./ItemListPageState";

export const ItemListPageContext = React.createContext({
  pageState: initialPageState,
  setPageState: (pageState: PageNewState) => { }
});

export const ItemListPageStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [pageState, setPageState]= useState(initialPageState);

  const context = {
    pageState,
    setPageState,
  };

  return (
    <ItemListPageContext.Provider value={context}>
      {children}
    </ItemListPageContext.Provider>
  )
};
import { useContext } from "react";
import { MenuPageContext } from "./MenuPageProvider";
import { PageNewState } from "./MenuPageState";

export const usePageState = () => {
  const {
    pageState,
    setPageState,
  } = useContext(MenuPageContext);

  const updater = (pageNewState: PageNewState) => {
    const reducer =
      pageNewState instanceof Function ? pageNewState : () => pageNewState;

    setPageState((prevState) => reducer(prevState));
  };

  return [pageState, updater] as const;
};
import React from "react";
import MenuPageContent from "./MenuPageContent";
import { MenuPageStateProvider } from "./MenuPageProvider";

export const MenuPage = () => {
  return (
    <MenuPageStateProvider>
      <MenuPageContent />
    </MenuPageStateProvider>
  )
}
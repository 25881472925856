import React, { useEffect, useRef } from "react";
import "./Information.css";

export default function Information() {
  const contentRef = useRef(null);

  // set up text to print, each item in array is new line
  const aText = [
    "<strong>Ngọc Bảo Các – Nơi Tụ Hội Tinh Hoa Ngọc Phỉ Thúy và Phong Thủy</strong>",
    `Xin chào mừng bạn đến với <strong>Ngọc Bảo Các</strong>, nơi lưu giữ vẻ đẹp tinh túy và tâm linh của Ngọc Phỉ Thúy, trang sức cao cấp và phong thủy.
    Chúng tôi tự hào mang đến cho bạn những sản phẩm tinh tuyển từ ngọc quý, giúp tôn lên nét thanh lịch và mang lại sự cân bằng năng lượng, an yên trong cuộc sống.`,
    `<strong>Ý Nghĩa Tên Gọi "Ngọc Bảo Các"</strong>`,
    `Tên gọi <strong>"Ngọc Bảo Các"</strong> bao hàm ba tầng ý nghĩa sâu sắc:`,
    `&#187; <strong>"Ngọc"</strong> tượng trưng cho vẻ đẹp vĩnh cửu, tinh khiết và tỏa sáng, là biểu tượng cho sự thanh cao, bền bỉ.`,
    `&#187; <strong>"Bảo"</strong> mang ý nghĩa bảo vật quý giá.`,
    `&#187; <strong>"Các"</strong> là không gian tôn nghiêm, nơi lưu giữ những giá trị cao quý.`,
    `<strong>Ngọc Bảo Các</strong> là nơi chúng tôi trân trọng từng món trang sức và vật phẩm phong thủy, mang đến cho bạn không chỉ vẻ đẹp bên ngoài
      mà còn giá trị tâm linh, giúp bảo vệ và cân bằng năng lượng.`,
    `<strong>Ngọc Phỉ Thúy – Giá Trị Phong Thủy Bền Lâu</strong>`,
    `Ngọc Phỉ Thúy từ xa xưa đã được yêu thích nhờ vẻ đẹp thanh khiết, màu sắc tươi sáng và nguồn năng lượng an lành. Tại <strong>Ngọc Bảo Các</strong>,
    mỗi viên ngọc thiên nhiên được chọn lựa kỹ lưỡng, mang năng lượng tốt lành giúp thu hút may mắn và bảo vệ người sử dụng. Vật phẩm phong thủy tại đây phong phú
    từ trang sức  (mặt ngọc, nhẫn, vòng cổ, vòng tay) đến những bảo vật mang lại tài lộc, an lành. Tất cả đều có nguồn gốc từ các mỏ ngọc quý trên thế giới, đi kèm
    giấy chứng nhận chất lượng từ các cơ quan uy tín.`,
    `<strong>Ngọc Bảo Các - Người Bạn Đồng Hành Tinh Thần</strong>`,
    `Chúng tôi hy vọng bạn tìm được vật phẩm yêu thích tại đây, cảm nhận vẻ đẹp và ý nghĩa sâu xa của phong thủy. <strong>Ngọc Bảo Các</strong>
    không chỉ là nơi cung cấp sản phẩm, mà còn là người bạn đồng hành cùng bạn trong hành trình khám phá vẻ đẹp ngọc và phong thủy, giúp bạn tự tin, tỏa sáng mỗi ngày.
    Chúng tôi sẽ cung cấp thông tin hữu ích về từng loại ngọc, câu chuyện gắn liền với mỗi viên đá, và tư vấn chọn lựa phù hợp với tử vi, cung mệnh của từng khách hàng.
    Với triết lý “Tâm dưỡng Ngọc – Ngọc dưỡng Nhân”, <strong>Ngọc Bảo Các</strong> mong muốn mang lại nguồn năng lượng tích cực, cải thiện sức khỏe, tình cảm và
    khơi thông tài vận như ý. Bên cạnh đó, chúng tôi sẵn lòng chia sẻ kiến thức về Phật giáo, Tử vi, Kinh dịch và các lĩnh vực tâm linh gần gũi.`,
    `<strong>Cam Kết Chất Lượng Sản Phẩm</strong>`,
    `<strong>Ngọc Bảo Các</strong> cam kết chất lượng trên từng sản phẩm. Quý khách có thể tự kiểm định chất lượng tại cửa hàng, hoặc qua các trung tâm thẩm định uy tín.
    Chúng tôi sẵn sàng chịu trách nhiệm nếu có bất kỳ thiếu sót nào trong chất lượng sản phẩm.`,
    `Trân trọng,`,
    `<strong>Ngọc Bảo Các</strong>`
  ];
  const iSpeed = 100; // time delay of print out
  let iIndex = 0; // start printing array at this position
  let iArrLength = aText[0].length; // the length of the text array
  const iScrollAt = 20; // start scrolling up at this many lines

  let iTextPos = 0; // initialize text position
  let sContents = ''; // initialize contents variable
  let iRow; // initialize current row
  let timeout: any;

  const typewriter = () => {
    if (!contentRef?.current || iIndex >= aText.length) return;

    sContents = ' ';
    iRow = Math.max(0, iIndex - iScrollAt);

    while (iRow < iIndex) {
      sContents += `<p> ${aText[iRow++]} </p>`;
    }
    (contentRef.current as any).innerHTML = sContents + `<p>${aText[iIndex].substring(0, iTextPos) + (iTextPos < iArrLength ? "_" : "")}</p>`;
    if (iTextPos++ === iArrLength) {
      iTextPos = 0;
      iIndex++;
      if (iIndex !== aText.length) {
        iArrLength = aText[iIndex].length;
        timeout = setTimeout(() => typewriter(), 400);
      }
    } else {
      if (iRow <= (aText.length - 1)) {
        timeout = setTimeout(() => typewriter(), iSpeed);
      } else {
        clearTimeout(timeout);
      }
    }
    const elements = document.getElementsByClassName("wrapper_inner");
    const scrollElement = elements.item(0);
    if(scrollElement) {
      scrollElement.scrollTo(0, scrollElement.scrollHeight);
    }
  }

  useEffect(() => typewriter(), []);

  return (
    // <div className="information-store typing">
    //   <div className="text-cover"></div>
    //   <p>
    //     <strong>
    //       Ngọc Bảo Các – Nơi Tụ Hội Tinh Hoa Ngọc Phỉ Thúy và Phong Thủy
    //     </strong>
    //   </p>
    //   <p>
    //     Xin chào mừng bạn đến với <strong>Ngọc Bảo Các</strong>, nơi lưu giữ vẻ đẹp tinh túy và tâm linh của Ngọc Phỉ Thúy, trang sức cao cấp và phong thủy.
    //     Chúng tôi tự hào mang đến cho bạn những sản phẩm tinh tuyển từ ngọc quý, giúp tôn lên nét thanh lịch và mang lại sự cân bằng năng lượng, an yên trong cuộc sống.
    //   </p>
    //   <p>
    //     <strong>
    //       Ý Nghĩa Tên Gọi "Ngọc Bảo Các"
    //     </strong>
    //   </p>
    //   <p>
    //     Tên gọi <strong>"Ngọc Bảo Các"</strong> bao hàm ba tầng ý nghĩa sâu sắc:
    //   </p>
    //   <p>
    //     &#187; <strong>"Ngọc"</strong> tượng trưng cho vẻ đẹp vĩnh cửu, tinh khiết và tỏa sáng, là biểu tượng cho sự thanh cao, bền bỉ.
    //   </p>
    //   <p>
    //     &#187; <strong>"Bảo"</strong> mang ý nghĩa bảo vật quý giá.
    //   </p>
    //   <p>
    //     &#187; <strong>"Các"</strong> là không gian tôn nghiêm, nơi lưu giữ những giá trị cao quý.
    //   </p>
    //   <p>
    //     <strong> Ngọc Bảo Các</strong> là nơi chúng tôi trân trọng từng món trang sức và vật phẩm phong thủy, mang đến cho bạn không chỉ vẻ đẹp bên ngoài
    //     mà còn giá trị tâm linh, giúp bảo vệ và cân bằng năng lượng.
    //   </p>
    //   <p>
    //     <strong>
    //       Ngọc Phỉ Thúy – Giá Trị Phong Thủy Bền Lâu
    //     </strong>
    //   </p>
    //   <p>
    //     Ngọc Phỉ Thúy từ xa xưa đã được yêu thích nhờ vẻ đẹp thanh khiết, màu sắc tươi sáng và nguồn năng lượng an lành. Tại <strong>Ngọc Bảo Các</strong>,
    //     mỗi viên ngọc thiên nhiên được chọn lựa kỹ lưỡng, mang năng lượng tốt lành giúp thu hút may mắn và bảo vệ người sử dụng. Vật phẩm phong thủy tại đây phong phú
    //     từ trang sức  (mặt ngọc, nhẫn, vòng cổ, vòng tay) đến những bảo vật mang lại tài lộc, an lành. Tất cả đều có nguồn gốc từ các mỏ ngọc quý trên thế giới, đi kèm
    //     giấy chứng nhận chất lượng từ các cơ quan uy tín.
    //   </p>
    //   <p>
    //     <strong>
    //       Ngọc Bảo Các - Người Bạn Đồng Hành Tinh Thần
    //     </strong>
    //   </p>
    //   <p>
    //     Chúng tôi hy vọng bạn tìm được vật phẩm yêu thích tại đây, cảm nhận vẻ đẹp và ý nghĩa sâu xa của phong thủy. <strong>Ngọc Bảo Các</strong>
    //     không chỉ là nơi cung cấp sản phẩm, mà còn là người bạn đồng hành cùng bạn trong hành trình khám phá vẻ đẹp ngọc và phong thủy, giúp bạn tự tin, tỏa sáng mỗi ngày.
    //     Chúng tôi sẽ cung cấp thông tin hữu ích về từng loại ngọc, câu chuyện gắn liền với mỗi viên đá, và tư vấn chọn lựa phù hợp với tử vi, cung mệnh của từng khách hàng.
    //     Với triết lý “Tâm dưỡng Ngọc – Ngọc dưỡng Nhân”, <strong>Ngọc Bảo Các</strong> mong muốn mang lại nguồn năng lượng tích cực, cải thiện sức khỏe, tình cảm và
    //     khơi thông tài vận như ý. Bên cạnh đó, chúng tôi sẵn lòng chia sẻ kiến thức về Phật giáo, Tử vi, Kinh dịch và các lĩnh vực tâm linh gần gũi.
    //   </p>
    //   <p>
    //     <strong>
    //       Cam Kết Chất Lượng Sản Phẩm
    //     </strong>
    //   </p>
    //   <p>
    //     <strong>Ngọc Bảo Các</strong> cam kết chất lượng trên từng sản phẩm. Quý khách có thể tự kiểm định chất lượng tại cửa hàng, hoặc qua các trung tâm thẩm định uy tín.
    //     Chúng tôi sẵn sàng chịu trách nhiệm nếu có bất kỳ thiếu sót nào trong chất lượng sản phẩm.
    //   </p>
    //   <p>
    //     Trân trọng,
    //   </p>
    //   <p>
    //     <strong>
    //       Ngọc Bảo Các
    //     </strong>
    //   </p>
    // </div>
    <div className="information-store" ref={contentRef}></div>
  );
}
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useWindowScroll } from "@uidotdev/usehooks";
import "./Layout.css";
import { MenuPage } from "../menu";

const RESPONSIVE: { [key: string]: number } = {
  "table": 600,
  "mobile": 500,
  "desktop": 800
};

export default function Layout() {
  const [isShowBackToTopIcon, setIsShowBackToTopIcon] = useState(false);
  const [{ x, y }, scrollTo] = useWindowScroll();

  const onClickedBackToTop = (): void => {
    scrollTo({ left: 0, top: 0, behavior: "smooth" });
    setIsShowBackToTopIcon(false);
  };

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  useEffect(() => {
    if (y && y > RESPONSIVE[getDeviceType()]) {
      setIsShowBackToTopIcon(true);
    }

    if (!y) {
      setIsShowBackToTopIcon(false);
    }
  }, [y]);

  return (
    <div className="wrapper woocommerce woocommerce-page">
      <div className="wrapper_inner">
        <MenuPage />
        <a id="back_to_top" className={isShowBackToTopIcon ? "on" : "off"} onClick={onClickedBackToTop}>
          <span className="fa-stack">
            <span aria-hidden="true" className="qode_icon_font_elegant arrow_carrot-up "></span>
          </span>
        </a>
        <div className="content">
          <div className="content_inner">
            <div className="full_width">
              <div className="full_width_inner">
                <div className="elementor">
                  <div className="elementor-inner">
                    <div className="elementor-section-wrap">
                      <section className="elementor-section elementor-top-section elementor-element elementor-section-full_width elementor-section-height-default elementor-section-height-default parallax_section_no qode_elementor_container_no">
                        <div className="elementor-container elementor-column-gap-default">
                          <div className="elementor-row">
                            <div className="elementor-column elementor-top-column elementor-element">
                              <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                  <div className="elementor-element elementor-widget elementor-widget-bridge_product_list">
                                    <div className="elementor-widget-container">
                                      <Outlet />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
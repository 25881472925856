import React from "react";
import { ItemListPageContent } from "./ItemListPageContent";
import { ItemListPageStateProvider } from "./ItemListPageProvider";

export const ItemListPage = () => {
  return (
    <ItemListPageStateProvider>
      <ItemListPageContent />
    </ItemListPageStateProvider>
  )
}
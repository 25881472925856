export type PageConfig = {
  id: string;
  label: string;
  path: string;
  isModal?: boolean;
};

export enum ApplicationPage {
  TRANG_CHU = "TRANG_CHU",
  SAN_PHAM = "SAN_PHAM",
  THONG_TIN_SAN_PHAM = "THONG_TIN_SAN_PHAM",
  THONG_TIN_CUA_HANG = "THONG_TIN_CUA_HANG",
  LIEN_HE = "LIEN_HE",
};

export enum ApplicationPageAdmin {
  DASHBOARD = "DASHBOARD",
  SAN_PHAM = "SAN_PHAM"
};

export const Pages: { [key: string]: PageConfig } = {
  TRANG_CHU: {
    id: ApplicationPage.TRANG_CHU,
    label: "Trang chủ",
    path: "/",
  },
  SAN_PHAM: {
    id: ApplicationPage.SAN_PHAM,
    label: "Sản phẩm",
    path: "/san-pham"
  },
  THONG_TIN_SAN_PHAM: {
    id: ApplicationPage.THONG_TIN_SAN_PHAM,
    label: "Thông tin sản phẩm",
    path: "/san-pham/:id"
  },
  THONG_TIN_CUA_HANG: {
    id: ApplicationPage.THONG_TIN_CUA_HANG,
    label: "Thông tin cửa hàng",
    path: "/thong-tin-cua-hang",
  },
  LIEN_HE: {
    id: ApplicationPage.LIEN_HE,
    label: "Liên hệ",
    path: "/lien-he",
  },
 
};

export const AdminPages = {
  DASHBOARD: {
    id: ApplicationPageAdmin.DASHBOARD,
    label: "Quản Lý",
    path: "/admin",
  },
  SAN_PHAM: {
    id: ApplicationPageAdmin.SAN_PHAM,
    label: "Quản Lý Sản phẩm",
    path: "/admin/san-pham",
  }
}
import React from "react";
import "./Contact.css";

export default function Contact() {
  return (
    <div className="contact">
      <h1 className="name">Ngọc Bảo Các</h1>
      <h5 className="major">Ngọc - Trang Sức - Phong Thủy</h5>
      <table className="information">
        <tr className="item">
          <td>
            <span>
              <strong>Địa chỉ:</strong>
            </span>
          </td>
          <td>
            <span>Số 13, Đường 54, phường Thảo Điền, TP.Thủ Đức, TP.Hồ Chí Minh</span>
          </td>
        </tr>
        <tr className="item">
          <td>
            <span>
              <strong>Giờ mở cửa:</strong>
            </span>
          </td>
          <td>
            <span>10:00 - 22:00 hàng ngày</span>
          </td>
        </tr>
        <tr className="item">
          <td>
            <span>
              <strong>Fan page:</strong>
            </span>
          </td>
          <td>
            <span className="q_social_icon_holder normal_social">
              <a href="https://www.instagram.com/ngocbaocac/" target="_blank">
                <span className="qode_icon_font_elegant social_instagram simple_social"></span>
              </a>
            </span>
            <span className="q_social_icon_holder normal_social">
              <a href="https://www.facebook.com/ngocbaocac/" target="_blank">
                <span className="qode_icon_font_elegant social_facebook simple_social"></span>
              </a>
            </span>
          </td>
        </tr>
        <tr className="item">
          <td>
            <span>
              <strong>Hotline:</strong>
            </span>
          </td>
          <td>
            <span>097.7979.995 - 09.79379.995</span>
          </td>
        </tr>
      </table>
    </div>
  );
}

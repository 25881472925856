import React, { PropsWithChildren, useReducer, useState } from "react";
import { ACTION, initPageState } from "./AppStateInitial";
import { Action, PageState } from "./AppState";
import { AppContext } from "./AppContext";
import { CategoryDictionary } from "../services/category";
import { ProductDictionary } from "../services";

export const AppStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [pageState, setPageState] = useState(initPageState);

  const context = {
    pageState,
    setPageState,
  };

  return (
    <AppContext.Provider value={context} >
      {children}
    </AppContext.Provider>
  );
};

export const appReducer = (state = initPageState, action: Action): PageState => {
  switch (action.type) {
    case ACTION.SAVE_CATEGORY:
      return { ...state, categories: action.payload as CategoryDictionary };
    case ACTION.SAVE_PRODUCTS:
      return { ...state, products: action.payload as ProductDictionary };
    default:
      return state;
  }
}

export const AppContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [pageState, dispatch] = useReducer(appReducer, initPageState);
  const [state, setPageState] = useState(initPageState);

  const setCategories = (categories: CategoryDictionary) => {
    dispatch({ type: ACTION.SAVE_CATEGORY, payload: categories });
  };

  const setProducts = (products: ProductDictionary) => {
    dispatch({ type: ACTION.SAVE_PRODUCTS, payload: products });
  };

  return (
    <AppContext.Provider value={{ pageState: state, setPageState }}>
      {children}
    </AppContext.Provider>
  )

}
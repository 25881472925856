import { useEffect, useState } from "react";
import { Category, CategoryDictionary } from "./Category";
import { database } from "../../config";
import { onValue, ref } from "firebase/database";
import { Product, ProductDictionary } from "../product";

export const useCategoryHook = () => {
  const [request, setRequest] = useState<boolean>(false);
  const [response, setResponse] = useState<CategoryDictionary>();

  useEffect(() => {
    if (!request) return;

    const productRef = ref(database, "categories");
    const unsubscribe = onValue(productRef, (snapshot) => {
      if (!snapshot.exists()) return;

      const categories: Category[] = snapshot.val();
      const categoriesDictionary: CategoryDictionary = {};
      categories.forEach((item) => categoriesDictionary[item.categoryId] = item);
      setResponse(categoriesDictionary);
    });
    return () => unsubscribe();
  }, [request]);

  return [response, () => setRequest(true)] as const;
}
import { useEffect, useState } from "react";
import { database } from "../../config";
import { ProductAdmin } from "./ProductAdmin";
import { orderByChild, query, ref, set, get, limitToFirst, orderByKey, limitToLast, orderByValue, push } from "firebase/database";
import { Product, ProductDictionary, ProductRequest } from "../product/Product";

export const useAddProductHook = () => {
    const [request, setRequest] = useState<Product>();
    const [response, setResponse] = useState<boolean>(false);

    useEffect(() => {
        if (!request) return;

        const productListRef = ref(database, `products/${request.itemId}`);
        const newProductRef = push(productListRef);
        set(productListRef, request).then(() => setResponse(true));

        return () => {
            setResponse(false);
            setRequest(undefined);
        };
    }, [request]);

    return [response, (request: Product) => setRequest(request)] as const;
};

export const useGetProductIdHook = () => {
    const [request, setRequest] = useState<boolean>(false);
    const [response, setResponse] = useState<number>();

    useEffect(() => {
        if (!request) return;

        const productRef = ref(database, 'products');
        const queryData = query(productRef, orderByKey(), limitToLast(1));
        get(queryData).then((snapshot) => {
            if (!snapshot.size) {
                setResponse(+1);
                return;
            }

            const itemId = Object.keys(snapshot.val() as ProductDictionary)[0];
            const newItemId = itemId ? itemId.slice(0, itemId.length - 3) : 1;
            setResponse(+newItemId + 1);
            setRequest(false);
        });
    }, [request]);

    return [response, () => setRequest(true)] as const;
};

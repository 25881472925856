import React, { useEffect } from 'react';
import './App.css';
import { AdminPages, ApplicationPage, ApplicationPageAdmin, Pages } from './application-page';
import { Route, Routes } from 'react-router-dom';
import Item from '../component/item/Item';
import Information from '../component/information/Information';
import Contact from '../component/contact/Contact';
import Layout from '../component/layout/Layout';
import { useAppState } from './usePageState';
import { useCategoryHook, useGetProductHook } from '../services';
import { Dashboard, ItemListAdmin } from '../admin';
import { ItemDetailPage } from '../component/item-detail';
import { ItemListPage } from '../component/item-list';

export function App() {
  const [pageState, setPageState] = useAppState();

  const [categoriesResponse, categoriesRequest] = useCategoryHook();
  const [productsResponse, productsRequest] = useGetProductHook();

  const onCategoriesResponse = () => {
    if (!categoriesResponse) return;

    setPageState(({
      ...pageState,
      categories: categoriesResponse,
    }));
  };

  useEffect(() => {
    categoriesRequest();
    productsRequest({});
  }, []);

  useEffect(onCategoriesResponse, [categoriesResponse]);

  useEffect(() => {
    if (!productsResponse) return;

    setPageState(({
      ...pageState,
      products: productsResponse,
    }));
  }, [productsResponse]);

  return (
    <Routes>
      <Route path="/"
        Component={Layout}>
        <Route Component={Item}>
          <Route path={Pages[ApplicationPage.SAN_PHAM].path} Component={ItemListPage} />
          <Route path={Pages[ApplicationPage.THONG_TIN_SAN_PHAM].path}
            Component={ItemDetailPage} />
          <Route index Component={ItemListPage} />
        </Route>
        <Route Component={Dashboard}>
          <Route path={AdminPages[ApplicationPageAdmin.SAN_PHAM].path}
            Component={ItemListAdmin} />
          <Route index Component={ItemListAdmin} />
        </Route>
        <Route path={Pages[ApplicationPage.THONG_TIN_CUA_HANG].path}
          Component={Information} />
        <Route path={Pages[ApplicationPage.LIEN_HE].path}
          Component={Contact} />
      </Route>
    </Routes>
  );
}
